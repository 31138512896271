import { useState, useEffect } from 'react';
import { useLocation, useNavigate } from "react-router-dom"
import BottomGrid2FLO from "../../components/BottomGrid2FLO";
import InputFieldNumFLO from "../../components/InputFieldNumFLO";
import AuthService from "../../Auth/auth";
import "./grid.css"
import HeaderSecondary from "../../components/headerSecondary/HeaderSecondary";
import Loader from "../../components/Loader/Loader";
import { useMediaQuery } from 'react-responsive'
import BottomGridEditFLO from '../../components/BottomGridEditFLO';
import DropDownOrientation from "../../components/DropDownOrientation";
import DropDownPosition from '../../components/DropDownPosition';
import { SpotWSS } from 'wf-utils/spot/spot-wss';
import normalOrientation from "../../assets/images/normalOrientation.png"
import widerOrientation from "../../assets/images/widerOrientation.png"
import config from '../../config';

const subscribeSpot = async (onData, assetId, templateId) => {
    const subscriptions = [{ assetId: assetId, templateId: templateId }];
    const key = localStorage.getItem("spaceio-key");
    const spotWs = new SpotWSS({
        subscriptions,
        wsUrl: config.WEB_SOCKET_URL,
        token: key,
        onData,
    });

    return () => {
        spotWs.disposeConnection();
    };
};

function FLOSetUp() {
    
    const location = useLocation();
    const navigate = useNavigate();

    let title = 'Set-Up';
    let floorId = location.state.selectedFLOSpaceData.deviceApi?.assignedSpace?.locationId;
    let floorName = location.state.selectedFLOSpaceData.deviceApi?.assignedSpace?.locationName;
    let room = location.state.selectedFLOSpaceData.deviceApi.displayName;
    let assetId = location.state.selectedFLOSpaceData.assetId;
    let humanShoulderHeight = 1.2192

    let [floConfigData, setfloConfigData] = useState({})

    const [params, setParams] = useState({
        enableFrame: true,
        width: 700,
        showKeys: false,
        showBorder: false,
        verticalLines: [],
        horizontalLines: [],
    })
    const [isEditing, setIsEditing] = useState(true);
    const [loading, setloading] = useState(false);
    const [resetCall, setResetCall] = useState(0);

    let [reset, setReset] = useState(true);
    let [zoneConfig, setZoneConfig] = useState(null);

    const isDesktopOrLaptop = useMediaQuery({
        query: '(min-width: 1224px)'
    })


    useEffect(function () {

        async function fetchData() {
            let key = window.localStorage.getItem('spaceio-key');
            if (key !== null) {
                setloading(true)
                const getData = await AuthService.getConfigZone([assetId]);
                setfloConfigData(getData);
                setZoneConfig(getData.zone);
                setloading(false);
            }
        }
        fetchData();
    }, [resetCall]);

    


    // useEffect(() => {
    //     // console.log('floConfigData',floConfigData)
    // }, [floConfigData])

    

    async function setupDone() {
        let key = localStorage.getItem('spaceio-key');
        let utcTimestamp = new Date().getTime();


        
      
        floConfigData[0].floMetaDataConfigStatus = 1
        // floConfigData[0].doorDimensions = floConfigData.doorDimensions
        // floConfigData[0].orientationAngle = floConfigData.orientationAngle
        // floConfigData[0].zone = floConfigData.zone

        // console.log(floConfigData)

        let dataToWrite = []
        dataToWrite = [{
            "assetId": assetId,
            "data": {
                ...floConfigData[0],
            }
        }]
        console.log('dataToWrite',dataToWrite)
        await AuthService.updateFLOConfig(dataToWrite)

        navigate(`/selectFLOfloor?floor=${floorId}&name=${floorName}`,
            { state: { ...location.state, "final data": floConfigData, } })
    }

    function resetAndDone() {
        setResetCall(resetCall + 1);
        setReset(!reset)
    }

    function resetCallback() {
        setResetCall(resetCall + 1);
    }

    const handleEditClick = () => {
        setIsEditing(false);
        setReset(false)
    };

    const handleInputChange = (value, key) => {
        // console.log('floConfigData------>',floConfigData)
        const formattedValue = (value.target.value).replace(/[^0-9.]/g, '').replace(/(\..{2}).+/g, '$1');
        if (formattedValue <= 10.00 && formattedValue >= 0.00) {
            floConfigData[0].doorDimensions = { ...floConfigData[0].doorDimensions, [key]: Number(formattedValue) }
            setfloConfigData({ ...floConfigData });
        }
    };

    const handleInputChangeForClearanceDist = (value, key) => {
        const formattedValue = (value.target.value).replace(/[^0-9.]/g, '').replace(/(\..{2}).+/g, '$1');
        if (formattedValue <= 0.5 && formattedValue >= 0) {
            floConfigData[0].doorDimensions = { ...floConfigData[0].doorDimensions, [key]: Number(formattedValue) }
            setfloConfigData({ ...floConfigData });
        }
    };

    const handleDropdownChange = (e, key) => {
        floConfigData[0].doorDimensions = { ...floConfigData[0].doorDimensions, [key]: e.target.value }
        setfloConfigData({ ...floConfigData });
    };

    const handleDropdownChangeorientationAngleData = (e, key) => {
        floConfigData[0] = { ...floConfigData[0], [key]: Number(e.target.value) }
        setfloConfigData({ ...floConfigData });
    }

    

    const creatZoneForDevice = (pixels, position, FloOrientation) => {
        let zone = [];
        if (FloOrientation === 0) {
            for (let row = 0; row < 24; row++) {
                for (let col = 0; col < 32; col++) {
                    if (position === "inSide") {
                        // zone will move outside i.e towords exit
                        if (col < (10 - pixels)) {
                            zone.push(0);
                        }
                        else if (col < (10 - pixels + 6)) {
                            zone.push(11);
                        }
                        else if (col < (10 - pixels + 12)) {
                            zone.push(12);
                        }
                        else {
                            zone.push(0);
                        }
                    }
                    else if (position === "outSide") {
                        // zone will move inside i.e towords entry
                        if (col < (10 + pixels)) {
                            zone.push(0);
                        }
                        else if (col < (10 + pixels + 6)) {
                            zone.push(11);
                        }
                        else if (col < (10 + pixels + 12)) {
                            zone.push(12);
                        }
                        else {
                            zone.push(0);
                        }
                    }
                }
            }
        }

        else if (FloOrientation === 180) {
            for (let row = 0; row < 24; row++) {
                for (let col = 0; col < 32; col++) {
                    if (position === "inSide") {
                        // zone will move outside i.e towords exit
                        if (col < (10 + pixels)) {
                            zone.push(0);
                        }
                        else if (col < (10 + pixels + 6)) {
                            zone.push(12);
                        }
                        else if (col < (10 + pixels + 12)) {
                            zone.push(11);
                        }
                        else {
                            zone.push(0);
                        }
                    }
                    else if (position === "outSide") {
                        // zone will move inside i.e towords entry
                        if (col < (10 - pixels)) {
                            zone.push(0);
                        }
                        else if (col < (10 - pixels + 6)) {
                            zone.push(12);
                        }
                        else if (col < (10 - pixels + 12)) {
                            zone.push(11);
                        }
                        else {
                            zone.push(0);
                        }
                    }
                }
            }
        }

        else if (FloOrientation === 90) {
            for (let row = 0; row < 24; row++) {
                for (let col = 0; col < 32; col++) {
                    if (position === "outSide") {
                        // zone will move outside i.e towords exit
                        if (row < (6 - pixels)) {
                            zone.push(0);
                        }
                        else if (row < (6 - pixels + 6)) {
                            zone.push(12);
                        }
                        else if (row < (6 - pixels + 12)) {
                            zone.push(11);
                        }
                        else {
                            zone.push(0);
                        }
                    }
                    else if (position === "inSide") {
                        // zone will move inside i.e towords entry
                        if (row < (6 + pixels)) {
                            zone.push(0);
                        }
                        else if (row < (6 + pixels + 6)) {
                            zone.push(12);
                        }
                        else if (row < (6 + pixels + 12)) {
                            zone.push(11);
                        }
                        else {
                            zone.push(0);
                        }
                    }
                }
            }
        }

        else if (FloOrientation === 270) {
            for (let row = 0; row < 24; row++) {
                for (let col = 0; col < 32; col++) {
                    if (position === "inSide") {
                        // zone will move outside i.e towords exit
                        if (row < (6 - pixels)) {
                            zone.push(0);
                        }
                        else if (row < (6 - pixels + 6)) {
                            zone.push(11);
                        }
                        else if (row < (6 - pixels + 12)) {
                            zone.push(12);
                        }
                        else {
                            zone.push(0);
                        }
                    }
                    else if (position === "outSide") {
                        // zone will move inside i.e towords entry
                        if (row < (6 + pixels)) {
                            zone.push(0);
                        }
                        else if (row < (6 + pixels + 6)) {
                            zone.push(11);
                        }
                        else if (row < (6 + pixels + 12)) {
                            zone.push(12);
                        }
                        else {
                            zone.push(0);
                        }
                    }
                }
            }
        }
        return zone;
    }

    const getFOV_L_W = (heightOfSensorFromFloor, humanHeight) => {
        const dimension = {
            areaLength: 0,
            areaWidth: 0,
            pixelLength: 0,
            pixelWidth: 0
        }
        dimension.areaLength = (2.856 * (heightOfSensorFromFloor - humanHeight)) / 1.5
        dimension.pixelLength = dimension.areaLength / 32

        dimension.areaWidth = 1.534 * (heightOfSensorFromFloor - humanHeight)
        dimension.pixelWidth = dimension.areaWidth / 24

        return dimension
    }

    const AdjustClearanceDistance = (heightOfSensorFromFloor, humanHeight, clearanceDistance, orientation, devicePosition) => {

        let dimensionObject = getFOV_L_W(heightOfSensorFromFloor, humanHeight)
        let numberOfPixelinsideCD = Math.ceil(
            clearanceDistance / dimensionObject.pixelLength
        )
        let zone = creatZoneForDevice(
            numberOfPixelinsideCD,
            devicePosition,
            orientation
        )
        return zone
    }



    const saveZoneInSpot = () => {
        let newZone = AdjustClearanceDistance(
            floConfigData[0].doorDimensions.sensor_height_fr_floor,
            humanShoulderHeight,
            floConfigData[0].doorDimensions.clearanceDistance,
            floConfigData[0].orientationAngle,
            floConfigData[0].doorDimensions.sensors_installed_at,

        );

        setZoneConfig(newZone);
        floConfigData[0].zone = newZone;
    }


    return (
        <div className="container-fluid g-0">
            <div className="row justify-content-center g-0 mainDiv">
                <div className="grid col-sm-12 col-lg-8" >
                    <div className="row g-0">
                        {loading && <Loader />}
                        {
                            <div className="col-12" style={{ background: "#F5F5F5", padding: '0 5px' }}>
                                <div className="row g-0">
                                    <div className="col-11 mx-auto" style={{ background: "#F5F5F5" }}>
                                        <HeaderSecondary primaryText={room} secondaryText={title} goBack={!reset ? () => { resetAndDone(); } : () => { navigate(-1) }} />
                                    </div>
                                </div>
                            </div>
                        }

                        <div className={`col-1${reset ? 2 : 2} mx-auto`} style={{ background: reset ? "" : '#F5F5F5', display: 'flex', justifyContent: 'space-around' }}>
                            <div className="gridHeaderHO" style={{ display: 'flex', flexDirection: 'row', justifyContent: `space-${isDesktopOrLaptop ? 'around' : 'between'}`, margin: '5px', gap: '2px' }}>
                                <div className="gridHeaderHO" style={{ display: 'flex', flexDirection: 'column', justifyContent: `space-${isDesktopOrLaptop ? 'around' : 'between'}`, margin: '5px', gap: '2px', width: '20%' }}>
                                    <img style={{ width: "100%" }} alt="normalOrientation" src={normalOrientation} />
                                </div>
                                <div className="gridHeaderHO" style={{ display: 'flex', flexDirection: 'column', justifyContent: `space-${isDesktopOrLaptop ? 'around' : 'between'}`, margin: '5px', gap: '2px', width: '20%' }}>
                                    <img style={{ width: "100%" }} alt="widerOrientation" src={widerOrientation} />
                                </div>

                                <div className="gridHeaderHO" style={{ display: 'flex', flexDirection: 'column', justifyContent: `space-${isDesktopOrLaptop ? 'around' : 'between'}`, margin: '5px', gap: '2px', width: '40%' }}>
                                    <InputFieldNumFLO
                                        width={isDesktopOrLaptop ? "25%" : "50%"}
                                        placeHolder=""
                                        reset={reset}
                                        label="Door Height (m):"
                                        value={floConfigData[0]?.doorDimensions?.door_height ?? ''}
                                        setValue={(value) => { handleInputChange(value, 'door_height') }}
                                        type="number"
                                        min={0.00}
                                        max={10.00}
                                        step={0.10}
                                        disabled={isEditing}
                                    />
                                    <InputFieldNumFLO
                                        width={isDesktopOrLaptop ? "25%" : "50%"}
                                        placeHolder=""
                                        reset={reset}
                                        label="Door Width (m):"
                                        value={floConfigData[0]?.doorDimensions?.door_width ?? ''}
                                        setValue={(value) => { handleInputChange(value, 'door_width') }}
                                        type="number"
                                        min={0.00}
                                        max={10.00}
                                        step={0.1}
                                        disabled={isEditing}
                                    />
                                    <InputFieldNumFLO
                                        width={isDesktopOrLaptop ? "25%" : "50%"}
                                        placeHolder=""
                                        reset={reset}
                                        label=" Height of Sensor (m):"
                                        value={floConfigData[0]?.doorDimensions?.sensor_height_fr_floor ?? ''}
                                        setValue={(value) => { handleInputChange(value, 'sensor_height_fr_floor') }}
                                        type="number"
                                        min={0.00}
                                        max={10.00}
                                        step={0.1}
                                        disabled={isEditing}
                                    />
                                </div>
                                <div className="gridHeaderHO" style={{ display: 'flex', flexDirection: 'column', justifyContent: `space-${isDesktopOrLaptop ? 'around' : 'between'}`, margin: '5px', gap: '2px', width: '40%' }}>
                                    <InputFieldNumFLO
                                        width={isDesktopOrLaptop ? "25%" : "50%"}
                                        placeHolder=""
                                        reset={reset}
                                        label="Clearance Distance (m):"
                                        value={floConfigData[0]?.doorDimensions?.clearanceDistance ?? ''}
                                        setValue={(value) => { handleInputChangeForClearanceDist(value, 'clearanceDistance') }}
                                        type="number"
                                        min={0}
                                        max={0.5}
                                        step={0.1}
                                        disabled={isEditing}
                                    />
                                    <DropDownOrientation
                                        width={isDesktopOrLaptop ? "25%" : "50%"}
                                        reset={reset}
                                        label="Orientation:"
                                        value={floConfigData[0]?.orientationAngle}
                                        setValue={(value) => { handleDropdownChangeorientationAngleData(value, 'orientationAngle') }}
                                        disabled={isEditing}
                                    />
                                    <DropDownPosition
                                        width={isDesktopOrLaptop ? "25%" : "50%"}
                                        reset={reset}
                                        label="Device Position:"
                                        value={floConfigData[0]?.doorDimensions?.sensors_installed_at}
                                        setValue={(value) => { handleDropdownChange(value, 'sensors_installed_at') }}
                                        disabled={isEditing}
                                    />
                                </div>
                            </div>

                        </div>
                    </div>
                    <br />

                    <div className={`col-1${reset ? 2 : 1} mx-auto`}>
                        {
                            reset ?
                                <BottomGridEditFLO
                                    title={title}
                                    room={room}
                                    buttonTextDone={"Set-Up Done"}
                                    buttonTextEdit={"Edit Position"}
                                    onClick={handleEditClick}
                                    setupDone={setupDone}
                                />
                                :
                                <BottomGrid2FLO
                                    title={title}
                                    room={room}
                                    showLeftRight={false}
                                    changeEdit={() => {
                                        setReset(!reset);
                                        setIsEditing(true);
                                        saveZoneInSpot();
                                    }}
                                    callback={resetCallback}
                                />
                        }
                    </div>
                    <br />
                </div>
            </div>
        </div >
    );
}

export default FLOSetUp;